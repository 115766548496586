/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #000 #fff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
}

.swing {
  transform-origin: top center;
  animation: swing 1s ease;
}

@font-face {
  font-family: "Proxima-Nova-Thin";
  src: url("../assets/fonts/ProximaNova-Thin.eot");
  src: url("../assets/fonts/ProximaNova-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/ProximaNova-Thin.woff2") format("woff2"),
    url("../assets/fonts/ProximaNova-Thin.woff") format("woff"),
    url("../assets/fonts/ProximaNova-Thin.ttf") format("truetype"),
    url("../assets/fonts/ProximaNova-Thin.svg#ProximaNova-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima-Nova-Bold";
  src: url("../assets/fonts/ProximaNova-Bold.eot");
  src: url("../assets/fonts/ProximaNova-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/ProximaNova-Bold.woff2") format("woff2"),
    url("../assets/fonts/ProximaNova-Bold.woff") format("woff"),
    url("../assets/fonts/ProximaNova-Bold.ttf") format("truetype"),
    url("../assets/fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima-Nova-Regular";
  src: url("../assets/fonts/ProximaNova-Regular.eot");
  src: url("../assets/fonts/ProximaNova-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("../assets/fonts/ProximaNova-Regular.woff") format("woff"),
    url("../assets/fonts/ProximaNova-Regular.ttf") format("truetype"),
    url("../assets/fonts/ProximaNova-Regular.svg#ProximaNova-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima-Nova-Black";
  src: url("../assets/fonts/ProximaNova-Black.eot");
  src: url("../assets/fonts/ProximaNova-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/ProximaNova-Black.woff2") format("woff2"),
    url("../assets/fonts/ProximaNova-Black.woff") format("woff"),
    url("../assets/fonts/ProximaNova-Black.ttf") format("truetype"),
    url("../assets/fonts/ProximaNova-Black.svg#ProximaNova-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima-Nova-SemiBold";
  src: url("../assets/fonts/ProximaNova-Semibold.eot");
  src: url("../assets/fonts/ProximaNova-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/ProximaNova-Semibold.woff2") format("woff2"),
    url("../assets/fonts/ProximaNova-Semibold.woff") format("woff"),
    url("../assets/fonts/ProximaNova-Semibold.ttf") format("truetype"),
    url("../assets/fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@keyframes swing {
  20% {
    transform: rotate(8deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.roll-out {
  animation: roll-out 1s ease;
}

.inputRange {
  appearance: none;
  width: 221px;
  height: 8px;
  background: linear-gradient(
    90deg,
    rgb(51, 136, 145) 1.51515%,
    rgba(51, 136, 145, 0.12) 1.51515%
  );
  cursor: pointer;
}

/* Thumb: for Chrome, Safari, Edge */
.inputRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 10000px;
  background: #338891;
  box-shadow: none;
}

/* Thumb: for Firefox */
.inputRange::-moz-range-thumb {
  border: none;
  width: 26px;
  height: 26px;
  border-radius: 10000px;
  background: #338891;
  box-shadow: none;
}

.inputRange2 {
  appearance: none;
  width: 221px;
  height: 8px;
  background: linear-gradient(
    90deg,
    rgb(51, 136, 145) 1.0101%,
    rgba(51, 136, 145, 0.12) 1.0101%
  );
  cursor: pointer;
}

/* Thumb: for Chrome, Safari, Edge */
.inputRange2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 10000px;
  background: #338891;
  box-shadow: none;
}

/* Thumb: for Firefox */
.inputRange2::-moz-range-thumb {
  border: none;
  width: 26px;
  height: 26px;
  border-radius: 10000px;
  background: #338891;
  box-shadow: none;
}

.calendly-inline-widget {
  height: 1200px !important;
  margin-bottom: -30px !important;
}

@media (max-width: 912px) {
  .calendly-inline-widget {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}
@keyframes roll-out {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translateX(100%) rotate(0deg);
  }
}

.slide-in {
  scale: 1 !important;
  opacity: 1 !important;
}

.fade-in {
  opacity: 1 !important;
}

.checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;

  cursor: pointer;

  font-size: 1.6rem;
  font-family: "Poppins-Regular";
  color: #1e1e1ecc;
}
.checkbox input {
  display: none;
  width: 0;
  height: 0;
}
.checkbox span {
  width: 1.6rem;
  height: 1.6rem;
  position: relative;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid black;
}

.checkbox input:checked + span {
  background-color: #338891;
}
.checkbox input:disabled + span {
  background-color: #ebebe4;
  border: 1px solid rgba(0, 0, 0, 0.35);
  cursor: not-allowed;
}
.checkbox span:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 3px;
  background-color: #338891;
}
.checkbox input:checked + span:before {
  animation: growAndFade 0.2s ease-out;
}
@keyframes growAndFade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.loading {
  filter: blur(2.5px);
  transition: filter 0.5s ease-out;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 1rem 0rem 0rem 1rem !important;

  /* background: transparent !important; */
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  /* background-color: transparent !important; */
  border-radius: 1rem 0rem 0rem 1rem !important;
}
